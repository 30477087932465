import React from "react"
import PropTypes from "prop-types"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container,
  Grid,
  Divider,
  Typography,
  Link as MaterialLink,
} from "@material-ui/core"

import { LocaleContext } from "../contexts/LocaleContext"
import Breadcrumbs from "../components/Breadcrumbs"
import AccommodationCard from "../components/cards/AccommodationCard"
import AccommodationMap from "../components/maps/AccommodationMap"
import NeedHelpBlock from "../components/NeedHelpBlock"
import ImageSlider from "../components/ImageSlider"
import SEO from "../components/SEO"
import { localizedSlugPrefix } from "../gatsby/nodeHelpers"

const useStyles = makeStyles(theme => ({
  topWrapper: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 60px)",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 110px)",
    },
  },
  mainImage: {
    height: "100%",
  },
  topOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.6)",
    top: 0,
    left: 0,
  },

  pageTitle: {
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: 700,
    textAlign: "center",
  },

  pageSubtitle: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
    textAlign: "center",
  },

  highlight: {
    background: theme.palette.primary.main,
  },

  intro: {
    marginTop: theme.spacing(3),
    lineHeight: 1.2,
    fontWeight: 700,
    letterSpacing: -1,

    "& p": {
      margin: 0,
    },
  },

  contents: {
    "& li": {
      fontSize: "1.3rem",
      listStyleType: "upper-roman",
    },
  },

  container: {
    marginTop: 30,
  },
}))

const AccommodationsCategory = ({
  data: { accommodationCategories, accommodations, accommodationsCategory },
  pageContext: { slug, pageSlug, breadcrumbs, locale, alternateLanguages },
  location,
}) => {
  const classes = useStyles()
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const accommodationList = accommodations.edges.map(r => r.node)

  const imageSlides = accommodationsCategory.data.photo_gallery.map(
    ({ photo }) => ({
      alt: photo.alt,
      fluid: photo.fluid,
    })
  )

  const [lastBreadcrumb] = breadcrumbs.slice(-1)
  const { path } = lastBreadcrumb
  const canonical = `${process.env.GATSBY_BASE_URL}${path}`

  const meta = {
    title: accommodationsCategory.data.meta_title,
    description: accommodationsCategory.data.meta_description,
    keywords: accommodationsCategory.data.meta_keywords,
    author: "Turist in Transilvania",
  }

  const schema = accommodationsCategory.data.schema_json.text || ""
  const ogp = accommodationsCategory.data.ogp_tags || []

  ogp.push({
    property: "og:image",
    content: accommodationsCategory.data.image.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: accommodationsCategory.data.image.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: accommodationsCategory.data.image.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: accommodationsCategory.data.image.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const parsedSchema = JSON.parse(schema)

  parsedSchema["@graph"].push({
    "@type": "ItemList",
    "@context": "https://schema.org/",
    itemListElement: accommodationList.map((accommodation, index) => ({
      "@type": "ListItem",
      name: accommodation.data.title.text,
      position: index + 1,
      item: null,
      url: `${process.env.GATSBY_BASE_URL}/${localizedSlugPrefix(
        accommodation
      )}/${slug}/${accommodation.uid}/`,
    })),
    name: accommodationsCategory.data.title.text,
    description: accommodationsCategory.data.intro.text,
    itemListOrder: "http://schema.org/ItemListOrderAscending",
  })

  parsedSchema["@graph"].push({
    "@type": "Hotel",
    "@context": "http://schema.org",
    description: accommodationsCategory.data.intro.text,
    image: accommodationsCategory.data.image.fluid.src,
    url: canonical,
    name: accommodationsCategory.data.title.text,
    priceRange: "$$",
  })

  const getAnchorOffset = () => {
    return 20
  }

  return (
    <>
      <SEO
        schema={JSON.stringify(parsedSchema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
        alternateLanguages={alternateLanguages}
      />
      <div className={classes.topWrapper}>
        <Img
          alt={accommodationsCategory.data.image.alt}
          className={classes.mainImage}
          fluid={accommodationsCategory.data.image.fluid}
        />
        <Container maxWidth="sm">
          <Grid
            direction="row"
            justify="center"
            alignItems="center"
            container
            className={classes.topOverlay}
          >
            <Grid item xs={8}>
              <Typography
                component="h1"
                variant="h1"
                className={classes.pageTitle}
              >
                {accommodationsCategory.data.title.text}
              </Typography>
              <br />

              <Typography
                component="h2"
                variant="h2"
                className={classes.pageSubtitle}
              >
                <span className={classes.highlight}>
                  {accommodationsCategory.data.subtitle.text}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <br />
      <Container maxWidth="lg">
        <Grid direction="row" alignItems="center" container>
          <Grid item xs={12} md={6}>
            <Typography
              component="h3"
              variant="h1"
              className={classes.intro}
              dangerouslySetInnerHTML={{
                __html: accommodationsCategory.data.intro.html,
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <br />
      <Container maxWidth="lg">
        <ul className={classes.contents}>
          <li>
            <MaterialLink
              component={AnchorLink}
              offset={getAnchorOffset()}
              href="#accommodations"
            >
              {i18n.accommodationsList}
            </MaterialLink>
          </li>
          <li>
            <MaterialLink
              component={AnchorLink}
              offset={getAnchorOffset()}
              href="#photo-gallery"
            >
              {i18n.gallery}
            </MaterialLink>
          </li>
          <li>
            <MaterialLink
              component={AnchorLink}
              offset={getAnchorOffset()}
              href="#location"
            >
              {accommodationsCategory.data.subtitle.text}
            </MaterialLink>
          </li>

          <li>
            <MaterialLink
              component={AnchorLink}
              offset={getAnchorOffset()}
              href="#getting-there"
            >
              {i18n.gettingThere}
            </MaterialLink>
          </li>
        </ul>
        <br />
        <NeedHelpBlock />
      </Container>

      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={accommodationList.length <= 1 ? 6 : 12}>
            <Typography component="h4" variant="h4" id="accommodations">
              {i18n.accommodationsList}
            </Typography>

            <Typography
              variant="body1"
              component="div"
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html:
                  accommodationsCategory.data.accommodations_description.html,
              }}
            />
          </Grid>
          {accommodationList.length <= 1 &&
            accommodationList.map(accommodation => (
              <Grid item xs={12} sm={6} key={accommodation.id}>
                <AccommodationCard accommodation={accommodation} />
              </Grid>
            ))}
        </Grid>
      </Container>

      {accommodationList.length > 1 && (
        <>
          <Container maxWidth="lg" className={classes.container}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="stretch"
              spacing={2}
            >
              {accommodationList.map(accommodation => (
                <Grid item xs={12} sm={6} lg={3} key={accommodation.id}>
                  <AccommodationCard accommodation={accommodation} />
                </Grid>
              ))}
            </Grid>
          </Container>
          <br />
          <Divider />
        </>
      )}

      <Container maxWidth="lg" className={classes.container}>
        <Typography component="h4" variant="h4" id="photo-gallery">
          {i18n.gallery}
        </Typography>
        <br />
        <ImageSlider slides={imageSlides} />
      </Container>
      <br />

      <Divider />

      <Container maxWidth="lg" className={classes.container}>
        <Typography component="h4" variant="h4" id="location">
          {accommodationsCategory.data.subtitle.text}
        </Typography>

        <Typography
          variant="body1"
          component="div"
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: accommodationsCategory.data.location_description.html,
          }}
        />
      </Container>
      <br />

      <Divider />

      <Container maxWidth="lg" className={classes.container}>
        <Typography component="h4" variant="h4" id="getting-there">
          {i18n.gettingThere}
        </Typography>

        <Typography
          variant="body1"
          component="div"
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: accommodationsCategory.data.getting_there_description.html,
          }}
        />
        <AccommodationMap
          pins={[
            {
              latitude: accommodationsCategory.data.coordinates.latitude,
              longitude: accommodationsCategory.data.coordinates.longitude,
              title: accommodationsCategory.data.title.text,
            },
          ]}
        />
      </Container>
    </>
  )
}

export default AccommodationsCategory

export const query = graphql`
  query AccommodationsCategoryQuery($slug: String!, $locale: String!) {
    accommodationsCategory: prismicAccommodationCategory(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      data {
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        meta_title
        meta_description
        meta_keywords
        image {
          alt
          dimensions {
            height
            width
          }
          fluid(maxWidth: 1980, maxHeight: 1070) {
            ...GatsbyPrismicImageFluid
          }
        }
        intro {
          html
        }
        title {
          text
        }
        subtitle {
          text
        }
        accommodations_description {
          html
        }
        location_description {
          html
        }
        getting_there_description {
          html
        }
        coordinates {
          latitude
          longitude
        }
        photo_gallery {
          photo {
            alt
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }

    accommodationCategories: allPrismicAccommodationCategory(
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          lang
          data {
            title {
              text
            }
            image {
              alt
              fluid(maxWidth: 90) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }

    accommodations: allPrismicAccommodation(
      filter: {
        lang: { eq: $locale }
        data: { category: { uid: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          id
          uid
          lang
          data {
            title {
              text
            }
            introduction {
              text
            }
            main_image {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            prices {
              amount
              currency
            }
            category {
              document {
                ... on PrismicAccommodationCategory {
                  lang
                  uid
                  data {
                    title {
                      text
                    }
                    parent {
                      document {
                        ... on PrismicAccommodationLanding {
                          id
                          uid
                        }
                      }
                    }
                  }
                }
              }
            }
            deposit_box
            stars
            dryer
            fridge
            grill_place
            kitchen
            non_smoking
            parking
            pet_friendly
            wifi
            terrace
            additional_bed
            accessibility
            pax_max
          }
        }
      }
    }
  }
`

AccommodationsCategory.propTypes = {
  data: PropTypes.shape({
    accommodationCategories: PropTypes.object.isRequired,
    accommodations: PropTypes.object.isRequired,
    accommodationsCategory: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    alternateLanguages: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
