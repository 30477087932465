import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { LocaleContext } from "../../contexts/LocaleContext"
import { localizedSlugPrefix } from "../../gatsby/nodeHelpers"

import CustomButton from "../buttons/CustomButton"

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: 220,

    height: "100%",
  },

  link: {
    textDecoration: "none",
    color: "inherit",
  },

  media: {
    height: 200,
  },

  content: {
    display: "flex",
    flexDirection: "column",
  },

  title: {
    fontSize: "1.15rem",
    fontWeight: 700,
    textTransform: "uppercase",
    display: "block",
    textDecoration: "none",
    color: theme.palette.common.black,
  },

  price: {
    color: theme.palette.turistBlue.main,
    fontWeight: 700,
  },
}))

const AccommodationCard = ({ accommodation }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const classes = useStyles()

  const landingUid =
    accommodation.data.category.document.data.parent.document.uid
  const categoryUid = accommodation.data.category.document.uid

  return (
    <Card className={classes.card} variant="outlined" square>
      <Grid
        container
        direction="column"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <Grid item>
          <CardMedia
            className={classes.media}
            image={accommodation.data.main_image.fluid.src}
            title={accommodation.data.title.text}
            component={Link}
            to={`/${localizedSlugPrefix(
              accommodation
            )}/${landingUid}/${categoryUid}/${accommodation.uid}`}
          />

          <CardContent>
            <Typography
              className={classes.title}
              component={Link}
              to={`/${localizedSlugPrefix(
                accommodation
              )}/${landingUid}/${categoryUid}/${accommodation.uid}`}
            >
              {accommodation.data.title.text}
            </Typography>

            <Typography component="span" variant="body1">
              {accommodation.data.introduction.text}
            </Typography>
          </CardContent>
        </Grid>
        <Grid item>
          <CardActions>
            <span className={classes.price}>
              {i18n.priceFrom} {accommodation.data.prices[0].amount}
              {` `}
              {accommodation.data.prices[0].currency} {i18n.perNight}
            </span>
          </CardActions>
          <CardActions>
            <CustomButton
              color="primary"
              component={Link}
              fullWidth
              to={`/${localizedSlugPrefix(
                accommodation
              )}/${landingUid}/${categoryUid}/${accommodation.uid}`}
            >
              {i18n.viewAccommodation}
            </CustomButton>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  )
}

export default AccommodationCard

AccommodationCard.propTypes = {
  accommodation: PropTypes.object.isRequired,
}
