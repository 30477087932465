import React from "react"
import shortid from "shortid"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  map: {
    width: "100%",
    zIndex: 9,

    height: 200,
  },
}))

const AccommodationMap = ({ pins }) => {
  const classes = useStyles()

  const getLatLngBounds = () => {
    return pins.map(pin => [pin.latitude, pin.longitude])
  }

  return <></>
}

export default AccommodationMap

AccommodationMap.propTypes = {
  pins: PropTypes.array.isRequired,
}
